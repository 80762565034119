import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {jwtDecode} from 'jwt-decode';
import moment from 'moment';

export const isAuthenticated = (token) => {
  if (token) {
    const decoded = jwtDecode(token);
    const currentTime = moment();
    const expirationTime = moment.unix(decoded.exp);
    return expirationTime.isAfter(currentTime);
  }
  return false;
};

const AuthGuard = ({ children }) => {
  const token = useSelector((state) => state.auth?.token);

  if (!token || !isAuthenticated(token)) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default AuthGuard;
