import React, { useEffect } from 'react';
import "./CustomModal.scss"

function CustomModal({ show, onHide, title, children, onCancel, onConfirm, className, modalImage, isAddClass = false, ...props }) {

   
    const modelImage = `/assets/${modalImage}`
   

    useEffect(() => {
        if (show) {
            document.body.classList.add('modal-open-noscroll');
        }
    
        return () => {
            
            document.body.classList.remove('modal-open-noscroll');
        };
    }, [show]);

    const handleBackdropClick = (e) => {
        if (e.target.classList.contains('custom-modal')) {
            onHide(); 
        }
    };

    const handleModalClick = (e) => {
        e.stopPropagation(); 
    };

 

    return (
        <div className={`custom-modal ${show ? 'show' : 'hide'} ${className}`}
            onClick={handleBackdropClick} // Detect clicks on the backdrop
            >
            <div className="custom-modal-content" onClick={handleModalClick}>
              
                <div className="custom-modal-body">
                    <div className="modal-body-main">
                        <div className='custom-modal-inner'>
                            {modalImage && <img src={modelImage} alt="Modal Image" />
                            }
                            <h3>{title}</h3>
                            {children}
                        </div>

                    </div>
                </div>
                
            </div>
        </div>
    );
}

export default CustomModal;