import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isAuthenticated } from './AuthGuard';

const PublicRouteGuard = ({ children }) => {
    const token = useSelector((state) => state?.auth?.token);


    if (token && isAuthenticated(token)) {
        return <Navigate to="/dashboard" replace />;
    }

    return children;
};

export default PublicRouteGuard;
