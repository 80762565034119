import React, { useState, useEffect } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "../pages/css/style.css";
import { Container, Nav, Navbar, Row, Col, NavDropdown } from "react-bootstrap";
import SobrietyLogo from "../assets/img/Soberiety-logo.svg";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FaBorderAll, FaCreditCard, FaDashcube, FaLock, FaMoneyCheck, FaUser } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { logout } from '../redux/loginSlice';
import CustomModal from './CustomModal';
import "../pages/css/Header.scss"

export default function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [navbarScrolled, setNavbarScrolled] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const { token, role, userInfo } = useSelector((state) => state?.auth);
  const [showModal, setShowModal] = useState(false);

  const modalContent = {
    title: 'Logout Confirmation',
    message: 'Are you sure you want to logout?',
    modalImage: 'Logout.png',
  };
  const handleScroll = () => {
    setNavbarScrolled(window.scrollY > 10);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);



  const openLogoutModal = () => {
    setShowModal(true); // Open the modal on logout click
  };

  const closeModal = () => {
    setShowModal(false); // Close modal without logging out
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  const handleNavigation = (section) => {
    if (window.location.pathname !== "/") {
      navigate("/", { state: { scrollTo: section } });
    } else {
      document.getElementById(section)?.scrollIntoView({ behavior: "smooth" });
    }
    setExpanded(false); // Close the navbar after navigation
  };


  return (
    <>
      <Row className="header g-0 position-relative ">
        <Col className="navbar-cont">
          <Navbar
            collapseOnSelect
            expand="lg"
            className={`navbar-custom ${navbarScrolled ? "scrolled" : ""}`}
            fixed="top"
            expanded={expanded}

          >
            <Container className='position-relative'>
              <Navbar.Brand onClick={() => handleNavigation("home")}>
                <img src={SobrietyLogo} alt="Sobriety Logo" />
              </Navbar.Brand>
              <Navbar.Toggle
                aria-controls="responsive-navbar-nav"
                onClick={() => setExpanded(expanded ? false : true)}
              />
              <Navbar.Collapse id="responsive-navbar-nav" >
                <Nav className="justify-content-center mx-auto soberiety_nav">
                  <Nav.Link onClick={() => handleNavigation("home")}>Home</Nav.Link>
                  <Nav.Link onClick={() => handleNavigation("about")}>About</Nav.Link>
                  <Nav.Link onClick={() => handleNavigation("features")}>Features</Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/get-app"
                    className="btn primary_btn mx-auto extra d-sm-block d-md-none px-5"
                  >
                    Login
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
              {token ?
                <Navbar className='profile-dropdown-main'>
                  <NavDropdown
                  className="profile-dropdown"
                    title={
                      <div
                        style={{
                          backgroundImage: `url('${!userInfo?.avatar
                            ? "/assets/Default_user.svg"
                            : userInfo?.avatar
                            }')`,
                        }}
                        className="user_profile_header"
                      ></div>
                    }
                  >
                      <NavDropdown.Item onClick={() => navigate("/dashboard")}>
                    <span className="me-2">
                    <FaBorderAll />
                    </span>
                    Dashboard
                  </NavDropdown.Item>
                    {/* { role === 1 ? <NavDropdown.Item onClick={() => navigate("/user-management")}>
                      <span className="me-2">
                        <FaUser />
                      </span>
                      User Management
                    </NavDropdown.Item> : ""} */}
                    <NavDropdown.Item onClick={() => navigate("/my-profile")}>
                      <span className="me-2">
                        <FaUser />
                      </span>
                      My Profile
                    </NavDropdown.Item>
                    {role === 1 || (role === 2 && userInfo?.department_status === 0) ? <> <NavDropdown.Item onClick={() => navigate("/purchased-plan")}>
                      <span className="me-2">
                        <FaMoneyCheck />
                      </span>
                      Manage Subscription
                    </NavDropdown.Item>
                      <NavDropdown.Item onClick={() => navigate("/cards")}>
                        <span className="me-2">
                        <FaCreditCard />
                        </span>
                        Manage Payment Methods
                      </NavDropdown.Item>
                    </> : ""}

                    <NavDropdown.Item onClick={() => navigate("/change-password")}>
                      <span className="me-2">
                        <FaLock />
                      </span>
                      Change Password
                    </NavDropdown.Item>

                    <NavDropdown.Item onClick={openLogoutModal}>
                      <span className="me-2">
                        <MdLogout />
                      </span>
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </Navbar> :
                <Navbar className="me-0 ms-auto  mx-auto extra d-none d-sm-block px-5 navbar navbar-expand navbar-light btn">
                  <Link to="/login" className="btn primary_btn ">
                    {" "}
                    Login{" "}
                  </Link>
                </Navbar>}

            </Container>
          </Navbar>
        </Col>
        {/* Custom Modal for Logout Confirmation */}
        {/* <CustomModal
        show={showModal}
        handleClose={closeModal} // Close modal without logout
        title={modalContent.title}
        message={modalContent.message}
        onLogout={handleLogout} // Actual logout on modal confirmation
      /> */}

        <CustomModal
          className="my-custom-modal success-modal"
          show={showModal}
          onHide={closeModal}
          title={modalContent.title}
          // onCancel={handleCancel2}
          // onConfirm={handleConfirm}
          modalImage={modalContent.modalImage}
        // modalImage={"success-icon.png"}
        // className={abcd}
        >
          {/* Add your modal content here */}
          <p>{modalContent.message}</p>
          <button onClick={handleLogout} className='soberiety_primaary_btn w-100 mt-3'>Yes</button>
          <button onClick={closeModal} className='soberiety_primaary_btn bg-transparent w-100'>No</button>
        </CustomModal>
      </Row >

    </>

  );
}
