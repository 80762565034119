import logo from './logo.svg';
import './App.css';
import AllRoutes from './routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import Store, { Persistor } from "./redux/store";
import { PersistGate } from 'redux-persist/integration/react';

function App() {
  return (
    <>
      <Provider store={Store}>
        <PersistGate persistor={Persistor}>
          <div className="App">
            <Toaster position="top-center" reverseOrder={false} />
            <AllRoutes />
          </div>
        </PersistGate >
      </Provider >
    </>
  );
}

export default App;