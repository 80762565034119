import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../components/Header';

const PrivateLayout = () => {
  return (
    <>
    <Header/>
      <Outlet /> {/* This renders the matched child route */}
    </>
  );
};

export default PrivateLayout;
